import React from 'react';
import { TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import { TextFieldProps } from './textfield.type';

export const StyledTextField = styled(({ ...props }: TextFieldProps) => <TextField {...props} />).withConfig({
    shouldForwardProp: (prop) => !['width', 'radius', 'size'].includes(prop),
})`
    ${({ theme, width, radius, size }) => css`
        border-radius: ${radius ? theme.radius[radius] : theme.radius.medium};
        color: ${theme.palette.common.text};
        font-weight: ${theme.base.fontWeight};
        font-family: ${theme.base.fontFace};
        font-size: ${theme.text.size.medium.fontSize};
        font-smooth: auto;
        margin: 0  !important;
        width: ${width};
        background: ${theme.palette.background.body};
        border-radius: 99rem;

        .MuiInputAdornment-root {
            width: 4rem;
            height: 4rem;
            min-width: 4rem;
            min-height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 99rem;
            margin: 0;
            background: ${theme.textfield.base.open.background};

            i {
                width: 1.4rem;
                height: 1.4rem;
                color: ${theme.select.base.open.color};
            }
        }

        .MuiInputBase-root {
            padding-right: 0.7rem;
            padding-left: 0.7rem;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.palette.common.border};
            border-radius: 99rem;
            top: 0;
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border-color: ${theme.palette.color.gray.main};
                    border-width: 0.1rem;                    
                }
            }
        }

        &.Mui-error {
            & > fieldset {
                border-color: ${theme.palette.color.negative.main}
                border-width: 0.1rem;
            }
        }

        &:hover {
            &&& .MuiOutlinedInput-notchedOutline {
                border-width: 0.1rem;
                border-color: ${theme.palette.color.gray.main};                
            }
        }

        & .Mui-disabled {           
            .MuiOutlinedInput-notchedOutline {
                border-color: ${theme.palette.common.border} !important;
            }
        }

        input {
            padding: ${size === 'small' ? '1rem 1rem' : '1.4rem 2rem 0'};
            height: ${size === 'small' ? 'unset' : '4.2rem'};
            color: ${theme.palette.common.text} !important;
            font-family: ${theme.base.fontFace};
            font-weight: ${theme.base.fontWeight};
            font-size: ${theme.text.size.medium.fontSize};            

            &.Mui-disabled {
                -webkit-text-fill-color: ${theme.palette.common.textMuted} !important;
                text-fill-color: ${theme.palette.common.textMuted} !important;
                color: ${theme.palette.common.textMuted} !important;
            }
        }

        label {
            top: 1.8rem;
            left: 2.5rem;
            font-smooth: auto;
            color: ${theme.palette.common.textMuted};
            font-family: ${theme.base.fontFace};
            font-weight: ${theme.base.fontWeight};
            font-size: ${theme.text.size.medium.fontSize};
            line-height: ${theme.text.size.medium.lineHeight};
            letter-spacing: 0;
            transform: unset;
            transition: all ${theme.base.transition};

            &.Mui-focused {
                top: 1.0rem;
                color: ${theme.palette.common.textMuted};
                font-size: ${theme.text.size.xsmall.fontSize};
                line-height: ${theme.text.size.xsmall.lineHeight};
            };

            &.MuiFormLabel-filled {
                top: 1.0rem;
                color: ${theme.palette.common.textMuted};
                font-size: ${theme.text.size.xsmall.fontSize};
                line-height: ${theme.text.size.xsmall.lineHeight};
            };
        };

        legend {
            display: none;
        }

        & .MuiFormHelperText-root {
            color: ${theme.palette.color.negative.main};
            font-family: ${theme.base.fontFace};
            font-weight: 700;
            font-size: ${theme.text.size.vsmall.fontSize};
        },
    `}
`;
